









































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { CurationService } from '@/domain/curation/CurationService';
import { apolloClient } from '@/apolloClient';
import {
  ListingCurationGroup,
  ListingCurationGroupParam,
} from '@/domain/curation/models/curation';
import { CommonState } from '@frientrip/domain';

const curationService: CurationService = new CurationService(apolloClient);

export default ContainerMixin.extend({
  data(): {
    curationId: string;
    description: string;
  } {
    return {
      curationId:
        this.$route.params.id == undefined ? '' : this.$route.params.id,
      description: '',
    };
  },
  created() {
    const result: Promise<ListingCurationGroup> =
      curationService.getCurationGroup(this.$route.params.id);

    result.then(value => {
      if (value.id != undefined) {
        this.curationId = value.id;
        this.description = value.description;
      }
    });
  },
  methods: {
    async curationGroupIdValidation(id: string) {
      const regex = /^[A-Z0-9_]+$/;
      return regex.test(id);
    },
    async updateCurationGroup() {
      this.curationId = this.curationId.toUpperCase();
      if (this.curationId.trim() == '') {
        this.showAlert({
          message: '큐레이션 ID는 빈 값일 수 없습니다.',
          type: 'danger',
        });
        return;
      }
      if (!(await this.curationGroupIdValidation(this.curationId.trim()))) {
        this.showAlert({
          message:
            '큐레이션 ID는 영문 대문자, 숫자, 특수문자(_) 조합만 입력할 수 있습니다.',
          type: 'danger',
        });
        return;
      }

      const param: ListingCurationGroupParam = {
        id: this.curationId,
        description: this.description,
        status: CommonState.ACTIVE,
      };
      try {
        await curationService.updateCurationGroup(
          this.$route.params.id != undefined,
          param
        );
        this.$notify({
          title: '내용 저장 완료',
          message: '큐레이션 그룹 요청 내용을 저장 완료 되었습니다.',
          type: 'success',
        });

        await this.$router.push('/curationGroup');
      } catch (error) {
        console.error(error);
        this.showErrorAlert(error.message);
      }
    },
  },
});
